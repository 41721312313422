import "./App.css";
import { supabase, getHighscore, getAlltime } from "./lib/SupaBaseHelper";
import React, { useCallback } from "react"; // import react

function App() {
  const [alltimeScore, setAlltimeScore] = React.useState([]);
  const [highscore, setHighscore] = React.useState([]);
  const [subscribed, setSubscribed] = React.useState(false);

  // const gestures = ["🤛🏽", "🤚🏼", "✌🏼", "🦎", "🖖🏾"];
  const gestures = [
    "😇",
    "🤪",
    "🤯",
    "🤬",
    "🤮",
    "🙊",
    "🙉",
    "🙈",
    "🤡",
    "🤠",
    "😭",
    "😎",
    "🤓",
    "🤖",
    "👽",
    "👾",
    "🤖",
  ];

  const listenToHighscore = useCallback(() => {
    setSubscribed(true);

    getHighscore().then((score) => {
      setHighscore(score);
      console.log("score", score);
    });
    getAlltime().then((data) => {
      setAlltimeScore(data);
    });

    const mySubscription = supabase
      .channel("playerchannel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "players",
        },
        (payload) => {
          console.log("payload", payload);
          getAlltime().then((data) => {
            setAlltimeScore(data);
          });
          getHighscore().then((data) => {
            setHighscore(data);
          });
        }
      )
      .subscribe();
  }, []);

  return (
    <div className="App">
      {!subscribed ? (
        <button onClick={listenToHighscore}>Listen</button>
      ) : (
        <div>
          <h1>HIGHSCORE</h1>
          <div className="two-columns">
            <div className="column">
              <h2>ALL-TIME</h2>
              {/* <table class="tableHighscore">
                <tr>
                  <th class="column1">Rank</th>
                  <th class="column2">Name</th>
                  <th class="column3">Score</th>
                </tr>
                <tr>
                  <th class="column1">1</th>
                  <td class="column2">wanensam</td>
                  <td class="column3">🥇&nbsp;22</td>
                </tr>
                <tr>
                  <th class="column1">2</th>
                  <td class="column2">robben</td>
                  <td class="column3">🥈&nbsp;15</td>
                </tr>
                <tr>
                  <th class="column1">3</th>
                  <td class="column2">bart</td>
                  <td class="column3">🥉&nbsp;10</td>
                </tr>
                <tr>
                  <th class="column1">4</th>
                  <td class="column2">manie</td>
                  <td class="column3">🤬&nbsp;10</td>
                </tr>
                <tr>
                  <th class="column1">5</th>
                  <td class="column2">otto3</td>
                  <td class="column3">🤯&nbsp;9</td>
                </tr>
                <tr>
                  <th class="column1">6</th>
                  <td class="column2">thijmen</td>
                  <td class="column3">🙉&nbsp;8</td>
                </tr>
                <tr>
                  <th class="column1">7</th>
                  <td class="column2">anera</td>
                  <td class="column3">😇&nbsp;8</td>
                </tr>
                <tr>
                  <th class="column1">8</th>
                  <td class="column2">jonas_02</td>
                  <td class="column3">👾&nbsp;8</td>
                </tr>
                <tr>
                  <th class="column1">9</th>
                  <td class="column2">sofie</td>
                  <td class="column3">🤬&nbsp;8</td>
                </tr>
                <tr>
                  <th class="column1">10</th>
                  <td class="column2">otto</td>
                  <td class="column3">🤖&nbsp;8</td>
                </tr>
              </table> */}
              <table className="tableHighscore">
                <tr>
                  <th className="column1">Rank</th>
                  <th className="column2">Name</th>
                  <th className="column3">Score</th>
                </tr>
                {alltimeScore?.scoreAlltime?.map((player, ind) => (
                  <tr>
                    <th className="column1">{ind + 1}</th>
                    <td className="column2">
                      {player.name}
                      <small>
                        &nbsp;&nbsp;&nbsp;
                        {new Date(player.created_at).toLocaleDateString()}
                      </small>
                    </td>
                    <td className="column3">
                      {ind == 0 ? "🥇" : ""}
                      {ind == 1 ? "🥈" : ""}
                      {ind == 2 ? "🥉" : ""}
                      {ind > 2
                        ? gestures[Math.floor(Math.random() * gestures.length)]
                        : ""}
                      &nbsp;
                      {player.score}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div className="column">
              <h2>THIS WEEK</h2>
              <table className="tableHighscore">
                <tr>
                  <th className="column1">Rank</th>
                  <th className="column2">Name</th>
                  <th className="column3">Score</th>
                </tr>
                {highscore?.scoreToday?.map((player, ind) => (
                  <tr>
                    <th className="column1">{ind + 1}</th>
                    <td className="column2">{player.name}</td>
                    <td className="column3">
                      {ind == 0 ? "🥇" : ""}
                      {ind == 1 ? "🥈" : ""}
                      {ind == 2 ? "🥉" : ""}
                      {ind > 2
                        ? gestures[Math.floor(Math.random() * gestures.length)]
                        : ""}
                      &nbsp;
                      {player.score}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

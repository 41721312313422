import { createClient } from "@supabase/supabase-js";
import { SUPABASE_URL, SUPABASE_KEY } from "../lib/constants.js";

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  realtime: {
    params: {
      eventsPerSecond: 1,
    },
  },
});

const getAlltime = async () => {
  const response = await supabase
    .from("players")
    .select("*")
    .order("score", { ascending: false })
    .order("created_at", { ascending: false })
    .limit(10);

  console.log(getAlltime);

  const scoreAlltime = response.data;
  const err = response.error;

  if (err) {
    console.log(err);
    return [];
  }

  return { scoreAlltime };
};

const getHighscore = async () => {
  // get the first day of this week
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const monday = new Date(today.setDate(diff));
  const mondayStr = new Date(monday).toISOString();

  // saturday 2023-04-01 19:00:00.000000+00
  const saturday = new Date("April 21, 2023 19:00:00");
  const saturdayyStr = new Date(saturday).toISOString();

  // const responseSaturday = await supabase
  //   .from("players")
  //   .select("*")
  //   .lt("created_at", saturdayyStr)
  //   .order("score", { ascending: false })
  //   .order("created_at", { ascending: false })
  //   .limit(10);

  // const scoreSaturday = responseSaturday.data;
  // const error = responseSaturday.error;

  const responseToday = await supabase
    .from("players")
    .select("*")
    .gt("created_at", mondayStr)
    .order("score", { ascending: false })
    .order("created_at", { ascending: false })
    .limit(10);

  const scoreToday = responseToday.data;
  const error2 = responseToday.error;

  if (error2) {
    console.log(error2);
    return [];
  }

  return { scoreToday };
};

export { supabase, getHighscore, getAlltime };
